import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'reducers/reducers';
import { CustomTypeValue, ITypeHeaderProps } from 'components/contentTypes/CustomTypeInput';
import { CustomTypeWithPublishState } from '../CustomTypeEditor';
import { ArchiveButton } from './ArchiveButton';
import { toggleArchived } from 'actions/ContentActions';
import { HeaderWrapper } from './ListHeaderWrapper';
import { Button, Popup } from 'semantic-ui-react';
import { useDownloadSurveyEntries } from 'api/surveyApi';

function entrySelector(id: string, type: string) {
  return (state: IState) => {
    const data = state.content.data[type] ?? [];

    return data.find((d) => d._id === id);
  };
}

export function SurveyHeader({ id, type, typeDescription }: ITypeHeaderProps) {
  const exportMutation = useDownloadSurveyEntries();
  const entry = useSelector(entrySelector(id, type));
  const [entryLocal, setEntryLocal] = useState<CustomTypeWithPublishState | undefined>(entry);
  const dispatch = useDispatch();

  const onToggleArchived = (value: CustomTypeValue) => {
    dispatch(toggleArchived(value));
  };

  useEffect(() => {
    if (entry !== entryLocal) {
      setEntryLocal(entry);
    }
  }, [entryLocal, entry]);

  if (!typeDescription || !entryLocal) return null;

  return (
    <HeaderWrapper>
      <Popup
        className="negative"
        open={exportMutation.isError}
        content="Failed to download data! See browser console for more information"
        trigger={
          <Button onClick={() => exportMutation.mutate(id)} disabled={exportMutation.isLoading}>
            Export Answers
          </Button>
        }
      />
      <ArchiveButton entry={entryLocal} onToggle={onToggleArchived} />
    </HeaderWrapper>
  );
}
