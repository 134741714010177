import { ObjectId } from '@visikon/core-models/base';
import { APIUtils } from 'commons/utils';
import { API, V2API } from '../config';

export const URLS = {
  metadata: {
    get: (type: string, id: string) => new URL(`./programs/${type}/${id}/metadata`, API.baseURL).toString(),
  },
  compositions: {
    all: () => new URL(`./blocklist`, API.baseURL).toString(),
    get: (id: string) => new URL(`./blocklist/${id}`, API.baseURL).toString(),
    copy: (id: string) => new URL(`./blocklist/${id}/copy`, API.baseURL).toString(),
  },
  blocks: {
    get: (id: string) => new URL(`./blocks/${id}`, API.baseURL).toString(),
    usage: (id: string) => new URL(`./blocks/${id}/usage`, V2API.baseURL).toString(),
    list: (page: number, limit: number = 15, name?: string, manuscript?: string, tags?: string, archived?: boolean) => {
      const url = APIUtils.addParams(`./blocks/${page}/${limit}`, { name, tags, manuscript, archived });
      return new URL(url, V2API.baseURL).toString();
    },
  },
  images: {
    get: (page: number, limit: number = 15, name?: string, tags?: string, archived?: boolean) => {
      const url = APIUtils.addParams(`./images/${page}/${limit}`, { name, tags, archived });
      return new URL(url, V2API.baseURL).toString();
    },
    getById: (id: ObjectId) => new URL(`./media/image/${id}`, API.baseURL).toString(),
  },
  texts: {
    get: (archived?: boolean) => {
      const url = APIUtils.addParams(`./texts`, { archived });
      return new URL(url, V2API.baseURL).toString();
    },
    getByLanguageId: (language: string, id: ObjectId) => new URL(`./texts/${language}/${id}`, V2API.baseURL).toString(),
    getById: (id: ObjectId) => new URL(`./texts/${id}`, V2API.baseURL).toString(),
    post: () => new URL(`./texts/`, V2API.baseURL).toString(),
    put: (id: ObjectId) => new URL(`./texts/${id}`, V2API.baseURL).toString(),
    patch: (id: ObjectId) => new URL(`./texts/${id}`, V2API.baseURL).toString(),
  },
  videos: {
    get: (id: ObjectId) => new URL(`./media/video/${id}`, API.baseURL).toString(),
    list: (page: number, limit: number, name?: string, tags?: string, archived?: boolean) => {
      const url = APIUtils.addParams(`./videos/${page}/${limit}`, { name, tags, archived });
      return new URL(url, V2API.baseURL).toString();
    },
  },
  idByTypeAndName: {
    post: (type: string) => new URL(`./content/idByName/${type}`, API.baseURL).toString(),
  },
  lastModified: {
    get: (id: string) => new URL(`./content/lastModified/${id}`, API.baseURL).toString(),
  },
  programs: (programId: string) => ({
    codes: {
      get: () => new URL(`./programs/${programId}/program-codes`, V2API.baseURL).toString(),
      post: () => new URL(`./programs/${programId}/program-codes`, V2API.baseURL).toString(),
    },
  }),
  text: {
    azureToken: {
      get: () => new URL(`./text/azureToken`, V2API.baseURL).toString(),
    },
  },
  encodings: {
    get: (id: string) => new URL(`./encodings/${id}`, API.baseURL).toString(),
  },
  publisher: {
    validate: (id: string) => new URL(`./publisher/validate/${id}`, API.baseURL).toString(),
  },
  surveys: {
    entries: (id: string) => new URL(`./survey/${id}/entries`, API.baseURL).toString(),
  },
};
