import { StringUtils } from 'commons/utils';
import { ContentContainerKey } from '@visikon/core-models/models/content/content-containers';
import { IParameters, ITypeDescription } from './CustomTypeInput';
import { ProgramHeader } from 'containers/content/headers/ProgramHeader';
import { ArchiveHeader } from 'containers/content/headers/ArchiveHeader';
import { SurveyHeader } from 'containers/content/headers/SurveyHeader';

/* ************************** *
 *  Mit Forløb Types          *
 * ************************** */

const menuLabel: IParameters = {
  type: 'string',
  desc: 'Menu label (Optional)',
};

const pageTitle: IParameters = {
  type: 'string',
  desc: 'Page title (Optional)',
};

const notificationsDesc: ITypeDescription = {
  meta: {
    name: 'notifications',
    displayName: 'Notification list',
    description: 'A list of notifications which are used in a particular program.',
    header: ArchiveHeader,
  },
  fields: {
    notificationListName: {
      type: 'string',
      desc: 'Name - (CMS)',
    },
    notificationOptions: {
      type: 'list',
      nameOfListElement: 'group',
      desc: 'Notification group',
      fields: {
        title: {
          type: 'string',
          desc: 'Group title',
          maxLength: 40,
        },
        description: {
          type: 'string',
          desc: 'Group description',
          maxLength: 100,
        },
        notifications: {
          type: 'list',
          nameOfListElement: 'notification',
          desc: 'Notifications in group',
          fields: {
            label: {
              type: 'string',
              desc: 'Short notification text shown in notification area',
            },
            content: {
              type: 'string',
              desc: 'Longer notification text shown inside app',
            },
            repeat: {
              type: 'boolean',
              desc: 'Repeat the notification daily',
            },
            conditions: {
              type: 'boolean',
              desc: 'Notification condition',
            },
            atHour: {
              type: 'number',
              desc: "When to show notification relative to operation date in hours. Use a negative number for 'hours before operation'.",
            },
            atMinute: {
              type: 'number',
              desc: 'Offset the hours above by some minutes.',
            },
            image: {
              type: 'image',
              desc: 'Image shown together with notification',
              size: 'small',
            },
          },
        },
      },
    },
  },
};

const faqDesc: ITypeDescription = {
  meta: {
    name: 'faq',
    displayName: 'FAQ',
    description: 'An FAQ with questions and answers. Separated into sections.',
    header: ArchiveHeader,
  },
  fields: {
    title: {
      type: 'string',
      desc: 'FAQ title',
      maxLength: 64,
    },
    menuLabel,
    pageTitle,
    sections: {
      type: 'list',
      nameOfListElement: 'section',
      desc: 'Section in FAQ',
      fields: {
        name: {
          type: 'string',
          desc: 'Section name',
        },
        thumbnail: {
          type: 'image',
          desc: 'Thumbnail for section',
        },
        questions: {
          type: 'list',
          nameOfListElement: 'question/answer',
          desc: 'Questions and answers',
          fields: {
            question: { type: 'string', desc: 'Question' },
            answer: { type: 'long-string', desc: 'Answers' },
          },
        },
      },
    },
  },
};

const videoListDesc: ITypeDescription = {
  meta: {
    name: 'videoList',
    displayName: 'Video playlist',
    description: 'Used to arrange videos in a playlist, for instance on the Video screen or in an Instruction Video list.',
    header: ArchiveHeader,
  },
  fields: {
    name: {
      type: 'string',
      desc: 'Name of entire playlist',
    },
    menuLabel,
    pageTitle,
    sections: {
      type: 'list',
      nameOfListElement: 'section',
      desc: 'Sections',
      fields: {
        sectionName: {
          type: 'string',
          desc: 'Section name',
        },
        sectionType: {
          type: 'choice',
          desc: `Section type (Use 'list' for step-guides)`,
          choices: ['', 'grid', 'list'],
        },
        videoList: {
          type: 'list',
          nameOfListElement: 'video',
          desc: 'Videos in section',
          fields: {
            name: {
              type: 'string',
              desc: 'Video name',
            },
            description: {
              type: 'long-string',
              desc: 'Video description (Descriptive text for step-guides)',
            },
            autoPlay: {
              type: 'boolean',
              desc: 'Loop (enables autoplay and loop for the video)',
            },
            video: {
              type: 'video',
              desc: 'Selected video',
            },
          },
        },
      },
    },
  },
};

const docDesc: ITypeDescription = {
  meta: {
    name: 'document',
    displayName: 'Document list',
    description: 'A document list contains all the document which are associated with a particular module.',
    header: ArchiveHeader,
  },
  fields: {
    documentName: {
      type: 'string',
      desc: 'Document name',
      maxLength: 64,
    },
    menuLabel,
    textList: {
      type: 'list',
      nameOfListElement: 'text',
      desc: 'Texts in document',
      fields: {
        textTitle: { type: 'string', desc: 'Title of this text as shown in the client' },
        image: { type: 'image', desc: 'Cover image for this text', size: 'small' },
        text: { type: 'text', desc: 'Text' },
      },
    },
  },
};

const symptomGuideSeverityChoises = [
  { text: 'Positive', value: 'positive', icon: 'green circle' },
  { text: 'Neutral', value: 'neutral', icon: 'yellow circle' },
  { text: 'Negative', value: 'negative', icon: 'red circle' },
];
const symptomGuideDesc: ITypeDescription = {
  meta: {
    name: 'symptom',
    displayName: 'Symptom Guide',
    description:
      'A symptom guide is a list of tymptoms together with a description of whether or not that symptom is normal or if further actions is required.',
    header: ArchiveHeader,
  },
  fields: {
    title: { type: 'string', desc: 'Title', maxLength: 64 },
    menuLabel,
    pageTitle,
    description: { type: 'long-string', desc: 'Guide description' },
    image: { type: 'image', desc: 'Image' },
    symptoms: {
      type: 'list',
      nameOfListElement: 'symptom',
      desc: 'Symptoms',
      fields: {
        name: { type: 'string', desc: 'Symptom name' },
        thumb: { type: 'image', desc: 'Choose thumbnail', size: 'small' },
        infoList: {
          type: 'list',
          nameOfListElement: 'symptom details',
          desc: 'Symptom descriptions',
          fields: {
            text: { type: 'long-string', desc: 'Symptom text' },
            subtext: { type: 'string', desc: 'Resolution or action' },
            severity: { type: 'choice', desc: 'Severity', choices: symptomGuideSeverityChoises },
            image: { type: 'image', desc: 'Optional image', size: 'small' },
          },
        },
      },
    },
  },
};

const contentContainerDesc: ITypeDescription = {
  meta: {
    name: ContentContainerKey,
    displayName: 'Content Container',
    description:
      'A content container is a collection of content with a title, description and optional image. N.B. a content container can contain other content lists',
    header: ArchiveHeader,
  },
  fields: {
    name: { type: 'string', desc: 'Name', maxLength: 64 },
    title: { type: 'string', desc: 'Title', maxLength: 64 },
    menuLabel,
    pageTitle,
    description: { type: 'long-string', desc: 'Description' },
    image: { type: 'image', desc: 'Image' },
    collapsible: { type: 'boolean', desc: 'Make the list collapsible' },
    content: {
      type: 'anyList',
      desc: 'Content',
    },
  },
};

const mitforlobPack: ITypeDescription = {
  meta: {
    name: 'mitforlobPack',
    displayName: 'Module',
    description: 'A module is a complete Mit Forløb content pack. It typically includes a video list, instruction video list, an FAQ and so on.',
    header: ProgramHeader,
  },
  fields: {
    name: {
      type: 'string',
      desc: 'Module name',
      maxLength: 100,
    },
    subName: {
      type: 'string',
      desc: 'Module Sub name',
      maxLength: 64,
    },
    department: {
      type: 'string',
      desc: 'Department name',
      maxLength: 64,
    },
    organization: {
      type: 'string',
      desc: 'Organization (hospital eg.) name',
      maxLength: 64,
    },
    footer: {
      type: 'string',
      desc: 'Footer text',
      maxLength: 100,
    },
    image: {
      type: 'image',
      desc: 'Thumbnail for the Module',
      size: 'small',
    },

    banner: {
      type: 'image',
      desc: 'Banner for department or organization',
    },
    logo: {
      type: 'image',
      desc: 'Logo for department or organization',
      size: 'small',
    },
    contactText: {
      type: 'text',
      desc: 'Text to show in the Contact dialog in app',
    },
    programType: {
      type: 'choice',
      desc: 'Module type',
      choices: ['operation', 'examination', 'treatment', 'information'],
    },
    anatomy: {
      type: 'choice',
      desc: 'Anatomy',
      choices: ['generic', 'male', 'female'],
    },
    genericHeader: {
      type: 'boolean',
      desc: 'Show module header on every page instead of page titles',
    },
    content: {
      type: 'map',
      fieldKeys: [
        { key: 'programVideos', displayName: 'Videos / Module Homepage' },
        { key: 'trainingVideos', displayName: 'Training Videos' },
        { key: 'instructionVideos', displayName: 'Instruction Videos' },
        { key: 'phamplets', displayName: 'Phamplets' },
        { key: 'symptoms', displayName: 'Symptoms' },
        { key: 'faq', displayName: 'FAQ' },
        { key: 'help', displayName: 'Help' },
        { key: 'notifications', displayName: 'Notifications' },
        { key: 'survey', displayName: 'Survey' },
      ],
      desc: 'Module content',
    },
  },
};

/* ************************** *
 *  Novo types                *
 * ************************** */

const diabetesGuideDesc: ITypeDescription = {
  meta: {
    name: 'diabetesGuide',
    displayName: 'Content Pack',
    description: 'A collection of content comprising a diabetes guide used by an app.',
  },
  fields: {
    name: {
      type: 'string',
      desc: 'Project name',
      maxLength: 64,
    },
    organization: {
      type: 'string',
      desc: 'Organization name',
      maxLength: 64,
    },
    logo: {
      type: 'image',
      desc: 'Logo for program',
      size: 'small',
    },
    content: {
      type: 'map',
      desc: 'Program content',
    },
  },
};

const wikiContentDesc: ITypeDescription = {
  meta: {
    name: 'wikiContent',
    displayName: 'Wiki content',
    description: 'A list of subjects with different types. Makes up a complete wiki.',
  },
  fields: {
    name: {
      type: 'string',
      desc: 'Wiki name',
      maxLength: 64,
    },
    subjectList: {
      type: 'list',
      nameOfListElement: 'subject',
      desc: 'Subjects in wiki',
      fields: {
        title: { type: 'string', desc: 'Subject title' },
        description: { type: 'long-string', desc: 'Subject description' },
        thumb: { type: 'image', desc: 'Thumbnail for subject', size: 'small' },
        content: { type: 'anyList', desc: 'Content' },
      },
    },
  },
};

const wikiEntryDesc: ITypeDescription = {
  meta: {
    name: 'wikiEntry',
    displayName: 'Wiki entry',
    description: 'Wiki entry',
  },
  fields: {
    title: { type: 'string', desc: 'Title of this entry as shown in the client' },
    description: { type: 'long-string', desc: 'Entry description' },
    text: { type: 'text', desc: 'Text' },
  },
};

const wikiLinkDesc: ITypeDescription = {
  meta: {
    name: 'wikiLink',
    displayName: 'Wiki link',
    description: 'Wiki link used for client redirection',
  },
  fields: {
    text: { type: 'string', desc: 'Link text' },
    image: { type: 'image', desc: 'Link image or thumbnail', size: 'small' },
    url: { type: 'string', desc: 'Link url' },
  },
};

const stringSequenceDesc: ITypeDescription = {
  meta: {
    name: 'stringSequence',
    displayName: 'String sequence with one or more list of strings',
    description: 'A list of strings',
  },
  fields: {
    name: { type: 'string', desc: 'Sequence name' },
    usePlus: { type: 'boolean', desc: 'Use plus sign' },
    start: { type: 'string', desc: 'Root text' },
    columns: {
      type: 'list',
      nameOfListElement: 'column',
      desc: 'Columns in sequence',
      fields: {
        strings: {
          type: 'list',
          nameOfListElement: 'string',
          desc: 'List of strings',
          fields: {
            string: { type: 'string', desc: 'String' },
          },
        },
      },
    },
  },
};

const drugListDesc: ITypeDescription = {
  meta: {
    name: 'drugList',
    displayName: 'Drug list',
    description: 'A list of drugs with sections.',
  },
  fields: {
    name: {
      type: 'string',
      desc: 'List name',
      maxLength: 64,
    },
    sections: {
      type: 'list',
      nameOfListElement: 'section',
      desc: 'Drug list section',
      fields: {
        sectionName: {
          type: 'string',
          desc: 'Section name',
        },
        drugs: {
          type: 'list',
          nameOfListElement: 'drug',
          desc: 'Drugs in section',
          fields: {
            title: { type: 'string', desc: 'Drug name' },
            description: { type: 'long-string', desc: 'Drug description' },
            text: { type: 'text', desc: 'Read more text' },
          },
        },
      },
    },
  },
};

const supportFaq: ITypeDescription = {
  meta: {
    name: 'supportFaq',
    displayName: 'FAQ',
    description: 'An FAQ with questions and answers. Separated into sections.',
  },
  fields: {
    title: {
      type: 'string',
      desc: 'Title',
      maxLength: 64,
    },
    description: {
      type: 'long-string',
      desc: 'Description',
    },
    sections: {
      type: 'list',
      nameOfListElement: 'section',
      desc: 'List of sections',
      fields: {
        name: {
          type: 'string',
          desc: 'Section name',
          maxLength: 30,
        },
        thumbnail: {
          type: 'image',
          size: 'small',
          desc: 'Section image/thumbnail',
        },
        questions: {
          type: 'list',
          nameOfListElement: 'question / answer',
          desc: 'Questions and answers',
          fields: {
            question: { type: 'string', desc: 'Question' },
            answer: { type: 'text', desc: 'Answer document' },
          },
        },
      },
    },
  },
};

const survey: ITypeDescription = {
  meta: {
    name: 'survey',
    displayName: 'Survey',
    description: '',
    header: SurveyHeader,
  },
  fields: {
    name: {
      type: 'string',
      desc: 'Internal name',
    },
    title: {
      type: 'string',
      desc: 'Survey title',
      maxLength: 64,
    },
    description: {
      type: 'long-string',
      desc: 'Description / Welcome message',
    },
    image: {
      type: 'image',
      size: 'small',
      desc: 'Image shown together with description / welcome message',
    },
    offset: {
      type: 'number',
      desc: 'Number of days from user activation before survey is shown',
    },
    invitationText: {
      type: 'string',
      desc: 'Invitation text shown in-app when survey is available (max 64 characters)',
      maxLength: 64,
    },
    notificationText: {
      type: 'string',
      desc: 'Notification text when the survey is available',
    },
    questions: {
      type: 'list',
      nameOfListElement: 'question',
      desc: 'Questions and answer',
      fields: {
        questionText: { type: 'string', desc: 'Question' },
        questionType: { type: 'choice', desc: 'Question type', choices: ['single', 'multi', 'text'] },
        hasOtherField: { type: 'boolean', desc: 'Include user defined anwser' },
        answers: {
          type: 'list',
          nameOfListElement: 'answers',
          desc: 'List of answer',
          fields: {
            text: { type: 'string', desc: 'Answer text' },
            score: { type: 'number', desc: 'Numeric answer value (e.g. for analysis)' },
          },
        },
      },
    },
  },
};

const typeDescriptors: { [key: string]: ITypeDescription } = {
  // Collection types
  mitforlobPack,
  diabetesGuide: diabetesGuideDesc,
  // Base content types
  faq: faqDesc,
  document: docDesc,
  symptom: symptomGuideDesc,
  videoList: videoListDesc,
  notifications: notificationsDesc,
  contentContainer: contentContainerDesc,
  // From novo
  wikiContent: wikiContentDesc,
  wikiEntry: wikiEntryDesc,
  wikiLink: wikiLinkDesc,
  drugList: drugListDesc,
  stringSequence: stringSequenceDesc,
  // Support
  supportFaq,
  survey,
};

export const doesTypeExist: (key: string) => boolean = (key) => key in typeDescriptors;

export const getTypeDescription: (key: string) => ITypeDescription | undefined = (key) => typeDescriptors[key];

export const typeDescriptionDisplayName = (typeDescription: ITypeDescription) =>
  typeDescription.meta.displayName || StringUtils.capitalizeFirstLetter(typeDescription.meta.name);
