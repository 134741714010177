import axios from 'axios';
import { APIUtils } from 'commons/utils';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { tokenState } from 'redux-recoil-sync.state';
import { URLS } from './urls';

const XLSX_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export function useDownloadSurveyEntries() {
  const [token] = useRecoilState(tokenState);

  return useMutation({
    mutationFn: async (surveyId: string) => {
      const response = await axios.get(URLS.surveys.entries(surveyId), {
        ...APIUtils.tokenHeader(token!, {
          accept: XLSX_CONTENT_TYPE,
        }),
        responseType: 'blob',
      });
      const filename = response.headers['content-disposition']?.split('filename=')[1] ?? `survey_entries_${surveyId}.xlsx`;
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');

      link.setAttribute('download', filename);
      link.href = href;

      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    retry: false,
  });
}
